// Verify Phone Start
// verify-phone ---
.step-verify-phone {
  .step-body {
    @media (max-width: 767px) {
      padding: 22px 0 0;
    }
  }
  .subtitle {
    margin-bottom: 46px;
    @media (max-width: 767px) {
      margin-bottom: 29px;
    }
  }
  .form-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    &.wrap-otp {
      display: none;
      &.is-active {
        display: block;
      }
    }
  }
  ~ .step-item {
    &.step-payment {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}
.form-item {
  position: relative;
  .title {
    font-size: 12px;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    color: #23354a;
    text-transform: uppercase;
    display: flex;
  }
  .change-btn {
    position: absolute;
    right: 0;
    top: 7px;
  }
  .country-code {
    position: absolute;
    left: 0;
    top: 4.5px;
    // padding-bottom: 2px;
  }
}
.wrap-number {
  margin-bottom: 25px;
  @media (max-width: 1064px) {
    margin-bottom: 60px;
  }
  @media (max-width: 767px) {
    margin-bottom: 30px;
  }
  .field-input {
    color: #292d3d;
    caret-color: #38a176;
    font-size: 12px;
    border: none;
    border-bottom: 2px solid #e9ecf3;
    outline: none;
    padding: 8px 0;
    width: 100%;
    border-radius: 0;
    &.hide-mobile {
      & ~ .bar {
        display: none;
      }
    }
  }
  label {
    &.error {
      top: 35px;
    }
  }
  .change-btn {
    display: none;
    &.is-active {
      display: flex;
    }
  }
}

// validdate text
.validate-form {
  .form-btn {
    pointer-events: none;
    &.is-active {
      pointer-events: unset;
    }
  }
}

.wrap-otp {
  position: relative;
  .change-btn {
    top: calc(100% + 10px);
    background-color: transparent;
    padding: 0;
  }
  .title {
    @media (max-width: 767px) {
      text-transform: none;
      font-size: 14px;
      letter-spacing: 0.3px;
      margin-bottom: 15px;
      .phone-number {
        margin: 0 5px;
      }
      .title-mobile {
        align-items: center;
        flex-wrap: wrap;
      }
      .change-btn {
        position: inherit;
        flex: 0 0 auto;
      }
      .country-code {
        position: inherit;
        flex: 0 0 auto;
      }
    }
  }

  // otp-timer
  .otp-timer {
    position: absolute;
    right: 0;
    top: calc(100% + 10px);
    font-size: 12px;
    color: #23354a;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    display: flex;
    font-size: 12px;
    .count {
      display: none;
      &.is-active {
        display: block;
      }
    }
  }

  .otp-timer2 {
    // position: absolute;
    right: 0;
    margin-bottom: 15px;
    // top: calc(100% + 10px);
    font-size: 12px;
    color: #23354a;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    .count {
      display: none;
      &.is-active {
        display: block;
      }
    }
  }

}
.verify-wrap {
  text-align: center;
}
.verify-section {
  height: fit-content;
}

.verify-phone-wrap {
  max-width: 400px;
  width: 100%;
  @media (max-width: 767px) {
    max-width: 100%;
    width: 100%;
    padding: 0 15px;
  }
  .otp-list {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    div {
      input {
        border: none;
        border-bottom: 2px solid #e9ecf3;
        max-width: 65px;
        width: 100%;
        text-align: center;
        padding: 3px 0;
        caret-color: #38a176;
        line-height: 1.5;
        border-radius: 0;
        &:focus {
          border-bottom: 2px solid #38a176;
        }
      }
    }
  }
  .wrap-number {
    .field-input {
      padding: 6px 0;
    }
  }
  .form-btn {
    // padding: 14px 0;
    width: 100%;
  }
  #orSpanText {
    display: block !important;
  }
  @media (max-width: 767px) {
    .form-btn {
      width: 100%;
    }
    #orSpanText {
      display: inline !important;
      padding: 0 5px;
    }
  }

  .close-btn {
    display: none;
    pointer-events: none;
  }

  @media (max-width: 767px) {
    .close-btn {
      display: block;
      pointer-events: auto;
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
}

.login-modal-backdrop {
  display: none;
  pointer-events: none;
}

@media (max-width: 767px) {
  .login-modal-backdrop {
    position: fixed;
    inset: 0;
    display: block;
    pointer-events: auto;
    &.is-disabled {
      display: none;
      pointer-events: none;
    }
  }
}

.proceed-to-login-pane {
  display: none;
  pointer-events: none;
}

@media (max-width: 767px) {
  .proceed-to-login-pane {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    pointer-events: auto;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: left;
    font-size: 12px;
    width: 100%;

    .price-btn {
      display: flex;
      flex-direction: column;
      padding: 10px 15px;
      background-color: #1a242c;
      width: 50%;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      pointer-events: none;

      .price-text {
        font-size: 14px;
        color: #98a5b7;
      }

      .price-desc {
        color: #38a176;
      }
    }

    .proceed-to-login-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      color: #fff;
      background-color: #38a176;
      width: 50%;
    }
  }
}

.loader-class {
  //   position: absolute;
  //   left: 0px;
  //   top: 0px;
  //   width: 100%;
  //   height: 100%;
  z-index: 9999;
  //   background: url("//upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif") 50% 50%
  //     no-repeat rgb(249, 249, 249);
}
// Verify Phone End
