// Form Sheet Start
// Material input --
.field-row {
  position: relative;
  .field-input {
    font-size: 12px;
    padding: 5px 0;
    display: flex;
    border: none;
    border-bottom: 2px solid #e9ecf3;
    background-color: #fff;
    /* active state */
    &:focus ~ label,
    &.valid ~ label {
      top: -15px;
      color: #231f20;
      &.error {
        text-align: left !important;
        top: 40px;
        @media only screen and (max-width: 767px) {
          top: 40px;
        }
      }
    }
    &.valid {
      ~ .bar {
        &:before,
        &:after {
          background: #38a176;
          width: 50%;
        }
      }
    }
    &.error {
      ~ .title {
        top: -15px;
      }
      ~ .bar {
        &:before,
        &:after {
          background: #e32c2b;
          width: 50%;
        }
      }
    }
    &.empty_field {
      ~ .title {
        top: 0;
      }
      &:focus ~ .title {
        top: -15px;
      }
    }
    /* active state */
    &:focus {
      ~ .bar:before,
      ~ .bar:after {
        width: 50%;
      }
    }
    // &:required {
    // 	padding: 5px 0;
    // }
    &:focus {
      outline: none;
      margin-top: 0;
      + .error {
        top: 30px;
      }
    }
  }
  label {
    color: #23354a;
    font-size: 12px;
    position: absolute;
    pointer-events: none;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    left: 0;
    top: 8px;
    text-transform: uppercase;
    letter-spacing: 0.26px;
    margin: 0;
    transition: 0.2s ease all;
    &.error {
      font-size: 10px !important;
      border: 0;
      top: 35px;
      position: absolute;
    }
  }
  /* BOTTOM BARS */
  .bar {
    position: relative;
    display: block;
    width: 100%;
    &:before,
    &:after {
      content: "";
      height: 2px;
      width: 0;
      bottom: 0;
      position: absolute;
      background: #38a176;
      transition: 0.2s ease all;
    }
    &:before {
      left: 50%;
    }
    &:after {
      right: 50%;
    }
  }
}
