// Base Styles
html,
body {
  margin: 0;
  padding: 0;
}

// Body Scroll

body::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px !important;
}

/* Track */
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #807c7c !important;
  border-radius: 10px !important;
}

// Other Scroll

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 8px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  outline: none;
  -webkit-appearance: none;
  &.disabled {
    pointer-events: none;
  }
}

.hide-mobile {
  display: none !important;
  @media (min-width: 768px) {
    display: block !important;
  }
}
.show-mobile {
  display: flex !important;
  @media (min-width: 768px) {
    display: none !important;
  }
}

// .is-disabled {
// 	Opacity: 0.6;
// }
.arrow {
  position: relative;
  display: flex;
  height: 13px;
  width: 8px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    background-color: #231f20;
    width: 2px;
    height: 8px;
    left: 0;
    right: 0;
    margin: auto;
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  &.arrow-right {
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }
  &.arrow-top {
    height: 8px;
    width: 13px;
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
      left: auto;
    }
  }
}

.form-btn {
  border-radius: 4px;
  font-size: 14px;
  font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  color: #98a5b7;
  background-color: #e5e7eb;
  width: 100%;
  padding: 11px 0;
  letter-spacing: 0.8px;
  position: relative;
  outline: none;
  @media (max-width: 768px) {
    font-size: 12px;
    border-radius: 0px;
  }
  &.is-active,
  &.green-btn {
    color: #fff;
    background-color: #38a176;
    pointer-events: auto;
  }
  &.is-active.outline-btn {
    border: 2px solid #38a176;
    background: white;
    color: #38a176;
  }
  &:focus {
    outline: none;
  }
}

.skip-btn {
  width: 15%;
  float: left;
  padding: 11px 0;
  font-size: 14px;
  font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  text-transform: uppercase;
  color: #98a5b7 !important;
  letter-spacing: 0.8px;
  position: relative;
  &:focus {
    outline: none;
  }
  @media (max-width: 768px) {
    font-size: 12px;
    border-radius: 0px;
  }
}

@media (max-width: 768px) {
  .header-image {
    height: 35px;
  }
}
@media (min-width: 769px) {
  .header-image {
    height: 45px;
  }
}

.wrap-btn {
  display: flex;
  .form-btn {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    &.total-btn {
      background-color: #1a242c;
      font-size: 14px;
      @media (max-width: 768px) {
        border-radius: 0;
      }
      .price-description {
        font-size: 12px;
        color: #38a176;
        font-family: Avenir-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
          "Open Sans", "Helvetica Neue", sans-serif;
      }
    }
    &.green-btn {
      justify-content: center;
      align-items: center;
    }
    &.proceed-btn {
      @media (max-width: 768px) {
        border-radius: 0;
      }
    }
  }
}

.nathabit-checkout-page {
  font-family: Avenir-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #edeef2;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .signout-button.mobile {
    display: none;
  }

  .signout-button.desktop {
    font-size: 12px;
    font-family: Avenir-Heavy;
    background: #38a176;
    padding: 5px;
    border-radius: 2px;
    color: white;
    float: right;
  }
  @media (max-width: 768px) {
    .signout-button.mobile {
      font-size: 12px;
      font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
      background: #38a176;
      padding: 5px;
      border-radius: 2px;
      color: white;
      display: block;
    }
    .signout-button.desktop {
      display: none;
    }
  }

  .main {
    margin: 0 auto;
    max-width: 1205px;
    width: 100%;
    padding: 16px 15px 0;
    @media (max-width: 767px) {
      padding: 4px 0 0;
    }
    &.align-center {
      margin: auto;
      padding: 0;
    }
  }
  .logo-wrap {
    max-width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #c7d1de;
    box-shadow: none;
    text-align: center;
    margin: 0;
    padding: 0;
    .logo {
      display: block;
      img {
        display: block;
        margin: auto;
      }
    }
  }
  .form-main {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
    }
    // step "add address" ---
    &.add-new-address {
      @media (max-width: 767px) {
        .right-sidebar {
          display: none;
        }
        .change-address-btn {
          display: block;
        }
      }
    }
    // step "proceed pay" ---
    &.proceed-pay-step {
      @media (min-width: 768px) {
        .step-add-address {
          .step-delivery {
            display: block;
          }
          .wrap-add-address {
            display: none;
          }
        }
        .step-choose-payment {
          display: block;
        }
      }
      @media (max-width: 767px) {
        // flex-direction: column-reverse;
        .wrap-step-payment,
        .step-choose-payment {
          display: block;
        }
        .right-sidebar.cart-main {
          display: none;
        }
        .notify-wrap {
          .change-address-btn {
            display: flex;
          }
        }
        .add-address-section {
          display: none;
        }
        .payment-back-btn {
          display: flex;
        }
        .wrap-add-address {
          display: none;
        }
      }
    }
    &.add-new-address,
		&.proceed-pay-step
		// &.choose-delivery-step
    {
      .step-add-address {
        @media (max-width: 767px) {
          position: relative;
          background-color: transparent;
          .wrap-add-address {
            padding: 0;
            #selectAddressContentSection > .step-title,
            #selectAddressContentSection > .subtitle {
              display: none;
            }
            .wrap-btn {
              display: flex;
              background-color: #fff;
              width: 100%;
              position: fixed;
              bottom: 0;
              padding: 8px 16px;
              .form-btn {
                padding: 18px 0;
                pointer-events: none;
                align-items: center;
                &.is-active {
                  pointer-events: auto;
                }
              }
            }
          }
          .step-body {
            padding: 0;
          }
          .step-content {
            flex-direction: column-reverse;
          }
          .add-address-section {
            .description,
            .subtitle {
              margin-bottom: 0;
            }
          }
          .notify-wrap {
            padding: 15px;
            background-color: #fff;
            &.selected {
              .radio-checkmark {
                border: 2px solid #38a176;
                &:after {
                  display: block;
                  border-radius: 50%;
                  background-color: #38a176;
                }
              }
            }
          }
          .wrap-new-address {
            border-bottom: 1px solid #cfd7e2;
            padding: 5px 15px;
            background-color: #fff;
            margin-bottom: 4px;
            .form-btn {
              font-size: 12px;
              color: #38a176;
              background-color: transparent;
              max-width: max-content;
              margin: 0 0 0 auto;
            }
            .description {
              display: flex;
              flex-direction: row;
              align-items: center;
              .back-btn {
                padding: 0;
              }
            }
          }
        }
      }
    }
    &.active-delivery-btn {
      @media (max-width: 767px) {
        .step-add-address {
          .wrap-add-address {
            .wrap-btn {
              .form-btn {
                color: #fff;
                background-color: #38a176;
                pointer-events: auto;
              }
            }
          }
        }
      }
    }
    &.choose-delivery-step {
      .cart-items {
        .wrap-btn {
          .form-btn {
            margin-top: 16px;
            padding: 18px 0;
          }
        }
      }
      @media (min-width: 768px) {
        .step-add-address {
          .step-delivery {
            display: block;
          }
          .wrap-add-address {
            display: none;
          }
        }
        .step-choose-payment {
          display: block;
        }
      }
    }
    &.delivery-address-step,
    &.change-address-step,
    &.choose-delivery-step {
      @media (max-width: 767px) {
        .right-sidebar.cart-main {
          display: block;
        }
        .wrap-add-address {
          display: none;
        }
        .step-delivery {
          display: block;
        }
        .btn-total {
          display: flex;
        }
      }
    }

    // success-step
    &.success-step {
      justify-content: center;

      .steps-bar {
        max-width: 400px;
      }

      @media (max-width: 768px) {
        .steps-bar {
          max-width: 100%;
        }
      }

      .step-success {
        display: block;
      }
      .wrap-step-payment,
      .step-add-address {
        display: none;
      }
      .right-sidebar {
        &.cart-items {
          display: block;
          .form-btn {
            margin-top: 16px;
            padding: 11px 0;
          }
        }
        &.cart-main {
          display: none;
        }
      }
      @media (max-width: 768px) {
        margin-bottom: 77px;
        .right-sidebar.cart-main {
          display: none;
        }
        .order-summary-section {
          .price-title {
            text-align: left;
          }
        }
        .right-sidebar.cart-items {
          display: block;
          .sidebar-cart {
            margin-bottom: 4px;
          }
          .order-summary-section {
            border: 1px solid #c7d1de;
          }
          // .product-list {
          // 	display: none;
          // }
          .wrap-btn {
            background-color: #fff;
            width: 100%;
            position: fixed;
            bottom: 0;
            padding: 8px 16px;
            box-shadow: 0 -5px 5px 0px rgba(192, 204, 221, 0.32);
            z-index: 2;
          }
          .form-btn {
            position: initial;
            max-width: 100%;
            margin: 0;
            // background-color: #fff;
            // border: 1px solid #38a176;
            // color: #38a176;
            // border-radius: 4px;
            font-size: 12px;
          }
        }
      }
      .step-verify-phone {
        display: none;
      }
    }
    // login/register
    &.login-register-step {
      .steps-bar {
        .step-title {
          margin-bottom: 5px;
          @media (max-width: 767px) {
            margin-bottom: 0;
          }
        }
      }
      // verify
      .verify-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 100%;
        margin-left: 40px;
        margin-top: 8px;
        padding: 32px 15px 30px;
        border-radius: 4px;
        border: 2px solid #e9ecf3;
        @media (min-width: 992px) {
          max-width: 224px;
        }
        @media (max-width: 767px) {
          display: none;
        }
        .img-wrap {
          margin-bottom: 15px;
        }
        .title {
          display: block;
          font-size: 14px;
          font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
            "Open Sans", "Helvetica Neue", sans-serif;
          color: #23354a;
          margin-bottom: 5px;
        }
        .subtitle {
          display: block;
          font-size: 12px;
          color: #a2adbe;
          margin-bottom: 0;
          line-height: 1.3;
          @media (max-width: 767px) {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 90%;
          }
        }
      }
      .step-verify-phone {
        @media (max-width: 767px) {
          position: fixed;
          bottom: 0;
          width: 100%;
          padding-bottom: 10px;
          z-index: 10;
        }
      }
      // .wrap-add-address {
      // 	@media (max-width: 767px) {
      // 		.step-title {
      // 			padding: 0 15px;
      // 		}
      // 	}
      // }
      .wrap-new-user {
        @media (min-width: 767px) {
          display: none;
        }
      }
      .success-login-wrap {
        display: none;
      }
      // mobile -v
      &.step-successful-login {
        .wrap-successful-login {
          display: block;
          @media (min-width: 767px) {
            display: none;
          }
        }
        .wrap-choose-address {
          @media (max-width: 767px) {
            display: none;
          }
        }
        &.add-new-address {
          .wrap-new-user {
            .checkout-address-item {
              display: flex;
            }
          }
        }
        .step-verify-phone {
          @media (max-width: 767px) {
            display: none;
          }
        }
      }
      .wrap-new-user {
        .checkout-address-item {
          display: none;
        }
      }
    }
  }
  .wrap-successful-login {
    display: none;
  }
  .steps-bar {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: 100%;
    z-index: 9;
    @media (min-width: 768px) {
      max-width: 744px;
    }
    .step-title {
      margin-bottom: 0;
    }
  }
  .step-item {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    @media (min-width: 768px) {
      margin-bottom: 8px;
      border: 1px solid #c7d1de;
      border-radius: 6px;
    }

    .step-body {
      // padding: 16px 40px 23px;
      padding: 21px 40px 21px;
      @media (max-width: 767px) {
        padding: 16px 0 0;
        border-top: 1px solid #cfd7e2;
        .wrap-add-address {
          padding: 0 16px;
        }
      }
    }

    &.active {
      .step-body {
        padding: 21px 40px 21px;
        @media (max-width: 767px) {
          padding: 22px 0 0;
        }
      }
    }
  }
  .step-number {
    background-color: #f9fafc;
    font-size: 12px;
    color: #8b97ad;
    padding: 14px 40px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .step-title {
    font-size: 14px;
    display: flex;
    color: #344254;
    margin-bottom: 10px;
    letter-spacing: 0.43px;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    @media (min-width: 768px) {
      font-size: 20px;
    }
  }
  // .step-body {
  // padding: 16px 40px 23px;
  // 	padding: 13px 40px 21px;
  // 	@media (max-width: 767px) {
  // 		padding: 16px 0 0;
  // 		border-top: 1px solid #CFD7E2;
  // 		.wrap-add-address {
  // 			padding: 0 16px;
  // 		}
  // 	}
  // }
  .step-content {
    display: flex;
    // align-items: flex-start;
  }
  .address-wrap {
    margin: 0 0 0 24px;
    width: 100%;
    @media (max-width: 767px) {
      margin: 0 0 0 15px;
    }
    .subtitle {
      margin-bottom: 15px;
    }
    .payment-system {
      display: block;
    }
  }
  .address-title {
    color: #23354a;
    font-size: 14px;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    display: flex;
    letter-spacing: 0.3px;
  }
  .subtitle {
    color: #a2adbe;
    font-size: 12px;
    display: flex;
    margin-bottom: 10px;
    line-height: 1.7;
    letter-spacing: 0.26px;
  }
  .change-btn {
    color: #38a176 !important;
    text-transform: uppercase;
    font-size: 12px;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    text-decoration: none !important;
    display: flex;
    justify-content: flex-end;
    flex: 1 1 0;
  }
  
  .resend-btns {
    color: #38a176 !important;
    text-transform: uppercase;
    font-size: 12px;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    text-decoration: none !important;
    display: flex;
    margin-bottom: 15px;
  }

  .sms-btn{
    display: flex;
    justify-content: flex-end;
    flex: 1 1 0;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 12px;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    text-decoration: none !important;
    padding: 0;
    // position: absolute;

  }

  .whatsapp-btn {
    // display: flex;
    background-color: transparent;
    text-transform: uppercase;
    font-size: 12px;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    text-decoration: none !important;
    padding: 0;
    // position: absolute;

  }

}

// back-btn
.back-btn {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
  font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #fff;
  padding: 15px;
  @media (min-width: 768px) {
    display: none;
  }
  .subtitle {
    font-size: 12px;
    font-family: Avenir-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    margin-bottom: 0;
    line-height: 1;
  }
  .back-arrow {
    margin-right: 15px;
    max-width: 16px;
  }
}

.notify-wrap {
  display: flex;
  align-items: flex-start;
  width: 100%;
  @media (max-width: 767px) {
    padding: 15px 0 0;
    &:not(:last-child) {
      border-bottom: 1px solid #e9ecf3;
    }
  }
  @media (min-width: 768px) {
    padding: 20px;
    border-radius: 4px;
    border: 2px solid #e9ecf3;
  }
  @media (min-width: 992px) {
    max-width: 302px;
    flex: 0 0 48%;
    &:hover {
      border: 2px solid #38a176;
      box-shadow: 0 15px 38px rgba(192, 204, 221, 0.32);
    }
  }
  .title {
    display: block;
    font-size: 14px;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    color: #23354a;
    margin-bottom: 5px;
  }
  .subtitle {
    display: block;
    font-size: 12px;
    color: #a2adbe;
    margin-bottom: 15px;
    line-height: 1.3;
    max-height: 72px;
    overflow: hidden;
    @media (max-width: 767px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90%;
    }
  }
  .form-btn {
    max-width: 100%;
    @media (min-width: 768px) {
      max-width: 128px;
    }
  }
  // address
  &.add-address-section {
    .img-wrap {
      margin-right: 25px;
      position: relative;
      @media (max-width: 767px) {
        margin-right: 15px;
      }
      &.add-new {
        &:after {
          content: "";
          position: absolute;
          display: block;
          top: -5px;
          right: -5px;
          background-color: #38a176;
          width: 18px;
          height: 18px;
          border: 2px solid #fff;
          border-radius: 50%;
        }
      }
    }
    .description {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (max-width: 767px) {
        margin-bottom: 10px;
        overflow: hidden;
      }
    }
    .change-address-btn {
      display: none;
    }
  }
  .delivery-info {
    color: #0af;
    font-size: 12px;
    cursor: pointer;
    @media (max-width: 767px) {
      display: none;
    }
  }
  &.wrap-new-address {
    .img-wrap {
      @media (max-width: 767px) {
        display: none;
      }
    }
    .title,
    .subtitle {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}

.step-address,
.step-success,
.cart-items {
  display: none;
}
.disable {
  display: none !important;
}
.active {
  display: block;
}

div.pac-container {
  z-index: 1050 !important;
}
// .field-input.error {
// 	border-bottom: 2px solid #e32c2b !important;
// }
label.error {
  color: #e32c2b !important;
  font-size: 12px;
  margin-bottom: 0;
  position: inherit;
}
.disable-form {
  pointer-events: none;
}
.overlay {
  &:after {
    @media (max-width: 767px) {
      content: "";
      background: rgba(0, 0, 0, 0.3);
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 8;
    }
  }
}
// Base Styles End

.main-img {
  max-width: 150px;
}
