// failure-success-section ---
.failure-success {
  text-align: center;
  color: #23354a;
  .success-row {
    padding: 25px 15px;
    text-align: center;
    @media (max-width: 767px) {
      background-color: #fff;
      // border: 1px solid #C7D1DE;
      display: flex;
      flex-direction: column;
      padding: 15px 15px;
    }
    &:not(:last-child) {
      border-bottom: 1px solid #c7d1de;
      @media (max-width: 767px) {
        // margin-bottom: 4px;
      }
    }
    &.title-row {
      //   background-color: #f9fafc;
      padding: 40px 15px 28px;
      @media (min-width: 768px) {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      @media (max-width: 767px) {
        padding: 20px 15px 10px !important;
      }
    }
    &.subtitle-row {
      padding: 35px 15px;
    }
    .wrap-img {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
      margin-bottom: 25px;
      img {
        margin-right: 10px;
        fill: #38a176;
      }
    }

    .order-section {
      display: flex;
      // justify-content: center;
      @media (max-width: 767px) {
        justify-content: flex-start;
        padding-left: 10;
      }
    }
    .order-name-container {
      padding-top: 5px;
      color: rgb(99, 106, 117);
      @media (max-width: 767px) {
        // padding-top: 10px;
        color: rgb(99, 106, 117);
      }
    }
    .order-name {
      color: #344254;
      font-size: 15px;
      font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
    }
  }

  .main-img {
    margin-bottom: 14px;
    @media (max-width: 767px) {
      width: 60px;
      height: 60px;
    }
  }
  .step-title,
  .subtitle {
    justify-content: center;
    align-self: flex-start;
  }
  .tel {
    font-size: 14px;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    margin-top: 15px;
    display: block;
    text-decoration: none;
    color: #23354a;
    @media (max-width: 767px) {
      margin-top: 0px;
      display: flex;
      align-self: flex-start;
    }
  }

  // success-section ---
  &.success-section {
    @media (max-width: 767px) {
      background-color: #edeef2;
    }
    .step-title {
      margin-bottom: 6px;
    }
    .form-btn {
      max-width: 128px;
      padding: 8px 0;
      opacity: 1;
      .disabled-btn {
        display: none;
        font-size: 12px;
        padding: 2px 0;
        .checkmark {
          width: 18px;
          height: 14px;
          margin: 0 7px;
          &:before,
          &:after {
            background-color: #98a5b7;
          }
        }
      }
      &.is-disabled {
        max-width: 143px;
        .active-btn {
          display: none;
        }
        .disabled-btn {
          display: flex;
        }
      }
    }
  }

  // failure section ---
  &.failure-section {
    width: 100%;
    max-width: 550px;
    margin: auto;
    .success-row {
      padding: 30px 0;
    }
    .title-row {
      padding: 40px 0px 15px;
    }
    .subtitle {
      max-width: 420px;
      margin: auto;
    }
    .order-summary-section {
      max-width: 320px;
      margin: auto;
      .price-title {
        text-align: left;
      }
    }
    .form-btn {
      margin-top: 20px;
      padding: 12px 0;
    }
  }

  // .success-container {
  //   @media (max-width: 767px) {
  //     display: flex;
  //     .main-img {
  //       width: 50px;
  //       height: auto;
  //     }
  // .step-title {
  // display: none;
  // }
  // .order-info {
  //   display: flex !important;
  //   flex-direction: column;
  //   align-items: flex-start;
  // }
  // .subtitle {
  //   display: none;
  // }
  // .mobile-title {
  //   padding-left: 15px;
  //   padding-top: 2px;
  // }
  //   .success-row {
  //     padding: 0px 0px !important;
  //   }
  // }
  // }
  .order-conditional-msg-container {
    display: flex;
    justify-content: center;
    @media (max-width: 767px) {
      justify-content: flex-start;
    }

    .order-conditional-msg {
      border: 1px solid #c7d1de;
      background-color: #fcfcfc;
      width: 100%;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      padding-right: 15px;
      // align-self: center;
      @media (max-width: 767px) {
        align-items: flex-start;
        flex: 1;
      }
      .order-info {
        font-size: 14px;
        // padding-top: 5px;
        text-align: left;
        color: rgb(99, 106, 117);
        @media (max-width: 767px) {
          font-size: 13px;
          // padding-left: 10px;
        }
        &:is(:last-child) {
          padding-bottom: 7px;
        }
      }
      .confirmed {
        padding-top: 7px;
        font-size: 18px;
        font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
          "Open Sans", "Helvetica Neue", sans-serif;
        color: #535357;
        @media (max-width: 767px) {
          font-size: 16px;
        }
      }
    }
  }
  .wa {
    @media (max-width: 767px) {
      visibility: hidden;
    }
  }
} // --- success-section
