// step-one
.nathabit-checkout-page {
  .non-deliverable-product {
    border: 1px solid #e81717 !important;
    border-radius: 6px;
    // padding: 20px !important;
    // margin-bottom: 10px;
    opacity: 0.8;
    // background-color: #fad1d0;
    // width: fit-content;
    z-index: 99999999;
  }
  // right - sidebar
  .right-sidebar {
    @media (min-width: 768px) {
      margin-left: 15px;
      max-width: 400px;
      width: 100%;
    }
    @media (min-width: 992px) {
      margin-left: 32px;
    }
    .sidebar-cart {
      color: #231f20;
      margin-bottom: 400px;
      @media (min-width: 768px) {
        padding: 23px 0;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #c7d1de;
        margin-bottom: 0;
      }
      .additional-off {
        background-color: rgb(174, 95, 117);
        color: white;
        padding: 6px 25px;
        font-size: 12px;
        font-weight: 600;
        margin-top: -5px;
        @media (min-width: 768px) {
          margin: -24px -1px 20px -1px;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
        }
      }
    }
    .cart-title {
      font-family: Avenir-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
      font-size: 20px;
      color: #344254;
      display: block;
      padding: 0 40px;
      letter-spacing: 0.43px;
      line-height: 1.5;
      @media (max-width: 767px) {
        padding: 15px;
        background-color: #fff;
        display: none;
        margin-bottom: 4px;
      }
      &.back-btn {
        display: flex;
        font-size: 12px;
        font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
          "Open Sans", "Helvetica Neue", sans-serif;
        @media (min-width: 768px) {
          display: none;
        }
      }
    }
    > .form-btn {
      margin-top: 16px;
      padding: 18px 0;
      @media (max-width: 767px) {
        padding: 14px 0;
        font-size: 12px;
      }
    }
    .order-summary-section {
      padding: 20px 40px 0;
      border-top: 1px solid #e9ecf3;
      @media (max-width: 767px) {
        padding: 20px 16px;
        background-color: #fff;
      }
    }
    .form-btn {
      @media (max-width: 767px) {
        // position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 10px 0 0;
      }
    }
    .product {
      flex-wrap: wrap;
      .cartitem-info,
      .cartitem-actions {
        display: flex;
        width: 100%;
        line-height: 1.5;
        min-height: 100px;
      }
    }
    &.cart-items {
      @media (max-width: 767px) {
        display: none;
        .wrap-btn {
          display: flex;
          flex-direction: column;
          background-color: #fff;
          width: 100%;
          position: fixed;
          bottom: 0;
          padding: 8px 16px;
        }
      }
    }

    .bill-details-skeleton-loader {
      padding: 20px 40px;
      border-top: 1px solid #e9ecf3;
      @media (max-width: 767px) {
        padding: 20px 16px;
        background-color: #fff;
      }

      .skeleton-heading {
        width: 80px;
        height: 20px;
        background-color: gray;
        border-radius: 4px;
        margin-bottom: 20px;
      }

      .skeleton-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .skeleton-title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .skeleton-title {
        width: 50px;
        height: 16px;
        background-color: gray;
        border-radius: 4px;
      }

      .skeleton-title-short {
        width: 30px;
        height: 16px;
        background-color: gray;
        border-radius: 4px;
      }

      .skeleton-title-long {
        width: 75px;
        height: 16px;
        background-color: gray;
        border-radius: 4px;
      }

      .skeleton-title-longer {
        width: 100px;
        height: 16px;
        background-color: gray;
        border-radius: 4px;
      }

      .skeleton-footer-container {
        display: flex;
        flex-direction: column;
      }

      .skeleton-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #e9ecf3;
      }

      .skeleton-footer-title {
        width: 60px;
        height: 20px;
        background-color: gray;
        border-radius: 4px;
      }

      .pulse {
        animation: shimmer 2s infinite;
      }

      @keyframes shimmer {
        0% {
          background-color: gray;
        }
        50% {
          background-color: lightgray;
        }
        100% {
          background-color: gray;
        }
      }
    }
  }
  .product-list {
    margin-bottom: 4px;
    @media (min-width: 767px) {
      max-height: 50vh;
    }
    overflow-y: scroll;
    padding: 0 20px;
    border-right: 8px white solid;
    border-top: 4px white solid;
    list-style: none;
    @media (max-width: 767px) {
      background-color: #fff;
      padding: 0 15px;
    }
    .product {
      display: flex;
      align-items: center;
      padding: 10px 0 5px;
      &:not(:last-child) {
        border-bottom: 1px solid #e9ecf3;
        margin-bottom: 2px;
      }
    }
  }
  .product-image {
    position: relative;
    min-width: 50px;
    .product-thumbnail {
      width: 100%;
      max-width: 60px;
      max-height: 50px;
      display: flex;
      img {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
      }
    }
  }
  .product-description {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
  }
  .product-title {
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    color: #231f20;
    font-size: 14px;
    letter-spacing: 0.3px;
  }
  .product-subtitle {
    font-size: 12px;
    margin-bottom: 0 !important;
    color: #a2adbe;
  }
  .product-price {
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 12px;
    margin: 0 0 0 auto;
    line-height: 1.5;
  }
  .discount-code-section {
    padding: 16px 40px;
    border-top: 1px solid #e9ecf3;
    background-color: #fcfdff;
    @media (max-width: 767px) {
      margin: 4px 0;
      padding: 15px 15px;
    }
    .discount-ico {
      margin-top: 11px;
      width: 23px;
    }
    .discount-code {
      position: relative;
      display: flex;
      align-items: center;
      width: calc(100% - 63px);
      padding: 0 10px;
      @media (max-width: 767px) {
        flex: 0 1 auto;
        margin: 0 2px;
      }
      @media (min-width: 768px) {
        padding: 0 25px 0 15px;
      }
      .input-wrap {
        width: 100%;
      }
      .field-input {
        width: 100%;

        &:focus::-webkit-input-placeholder {
          color: transparent;
        }
        /* Firefox < 19 */
        &:focus:-moz-placeholder {
          color: transparent;
        }
        /* Firefox > 19 */
        &:focus::-moz-placeholder {
          color: transparent;
        }
        /* Internet Explorer 10 */
        &:focus:-ms-input-placeholder {
          color: transparent;
        }
      }
      .remove-value {
        background-image: url(../../../assets/img/ic_clear.png);
        background-repeat: no-repeat;
        cursor: default;
        height: 20px;
        width: 20px;
        display: block;
        position: absolute;
        top: 11px;
        right: 35px;
        opacity: 0;
        overflow: hidden;
        transition: all 0.2s;
        @media (max-width: 767px) {
          right: 20px;
        }
        pointer-events: none;
        &.is-active {
          opacity: 1;
          overflow: visible;
          cursor: pointer;
          pointer-events: all;
        }
      }
    }

    .checkmark {
      &:before {
        right: calc(50% - 4px);
        transform: rotate(45deg);
        top: calc(50% - 8.5px);
        height: 17px;
      }
      &:after {
        left: calc(50% - 6px);
        top: calc(50% + 0px);
      }
    }

    .field-input {
      &.error {
        border-color: #cb2e2e;
        // background-image: url(../img/ic_clear-error.png);
      }
      &.valid {
        border-color: #38a176;
      }
    }
  }

  .form-discount {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 767px) {
      justify-content: space-around;
    }
    &.form-error {
      .remove-value {
        background-image: url(../../../assets/img/ic_clear-error.png);
      }
      // .close-ico {
      // 	background-color: #cb2e2e;
      // 	color: #fff;
      // }
    }
    &.form-active {
      .field-input {
        border-color: #38a176;
        // background-image: url(../img/ic_clear.png);
      }
      .apply-btn {
        background-color: #38a176;
        color: #fff;
        pointer-events: unset;
        .checkmark {
          &:before,
          &:after {
            background-color: #fff;
          }
        }
        .applymark {
          &:before,
          &:after {
            color: #fff;
          }
        }
      }
    }
    .field-input {
      border: 1px solid #e9ecf3;
      border-radius: 4px;
      color: #3e4152;
      font-size: 14px;
      padding: 9px 15px;
      font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
      outline: none;
      line-height: 1.5;
      &::placeholder {
        font-size: 14px;
      }
    }
  }

  .order-summary-section {
    .summary-row {
      display: flex;
      justify-content: space-between;
    }
    .subtotal-row {
      margin: 0 0 0px;
      &:last-child {
        margin: 0;
      }
    }
    .total-row {
      padding: 18px 0;
      margin: 20px 0 0;
      border-top: 1px solid #e9ecf3;
      align-items: center;
      color: #231f20;
      font-size: 16px;
      font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
      .total-name,
      .total-price {
        font-size: 16px;
      }
    }
    .discount-row {
      // padding: 22px 0 0;
      // border-top: 1px solid #e9ecf3;
      // color: #38a176;
      // margin-top: 15px;
      // margin-bottom: 3px;
      font-size: 14px;
      display: none;
      &.active {
        display: flex;
      }
    }
    .mrpdiscount-row {
      // padding: 22px 0 0;
      // border-top: 1px solid #e9ecf3;
      // margin-bottom: 3px;
      // color: #231F20;
      // margin-top: 15px;
      font-size: 14px;
      // display: none;
      display: flex;
      &:hover {
        .discount-tooltip {
          display: block;
        }
      }
    }

    .discount-color {
      color: #38a176;
    }

    .strikeshipping {
      color: #5c5d5f;
      text-decoration: line-through;
    }

    .subtotal-name {
      font-size: 12px;
      display: flex;
      align-items: center;
      flex: 1 1 0;
      line-height: 1.5;
    }
    .product-txt {
      font-size: 12px;
    }
    .total-price {
      font-size: 22px;
      font-weight: 500;
      line-height: 1.5;
    }
  }
  .price-title {
    font-size: 16px;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    margin-bottom: 10px;
    display: block;
    line-height: 1.5;
  }
  // quantity
  .cartitem-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
  .cartitem-quantity {
    display: flex;
    border: 2px solid #e9ecf3;
    border-radius: 4px;
    padding: 3px 9px;
    button {
      border: none;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      line-height: 1;
      &.minus,
      &.plus {
        position: relative;
        width: 14px;
        display: block;
        &:after,
        &:before {
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          display: block;
        }
        &:after {
          background-color: #e9ecf3;
          width: 14px;
          height: 2px;
        }
      }
      &.plus {
        &:before {
          background-color: #38a176;
          height: 14px;
          width: 2px;
        }
        &:after {
          background-color: #38a176;
        }
      }
    }
    .product_value {
      border: none;
      text-align: center;
      max-width: 40px;
      color: #38a176;

      font-size: 12px;
      font-family: Avenir-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
      line-height: 1;
    }
  }
  .apply-btn {
    color: #98a5b7;
    // width: 40px;
    padding: 10px;
    height: 40px;
    border: none;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    background-color: #e5e7eb;
    outline: none;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    .checkmark {
      &:before,
      &:after {
        background-color: #98a5b7;
      }
    }
    .applymark {
      &:before,
      &:after {
        background-color: #fff;
      }
    }
  }

  .applymark {
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 14px;
    letter-spacing: 0.8px;
  }

  .checkmark {
    position: relative;
    width: 18px;
    height: 18px;
    &:before,
    &:after {
      display: block;
      content: "";
      position: absolute;
      background-color: #fff;
      width: 2px;
      height: 6px;
    }
    &:before {
      right: 6px;
      left: auto;
      transform: rotate(45deg);
      top: 3.5px;
      width: 2px;
      height: 11px;
    }
    &:after {
      left: 5px;
      transform: rotate(-45deg);
      top: 7.5px;
    }
  }

  // Toltip
  .toltip-wrapper {
    position: relative;
    border-radius: 50%;
    cursor: pointer;
    line-height: 1;
    border: 1px solid #231f20;
    width: 100%;
    max-width: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 14px;
    height: 100%;
    font-size: 12px;
    margin-left: 10px;
    &:hover {
      .toltip {
        display: block;
      }
    }
  }

  .discount-wrapper {
    position: relative;
    cursor: pointer;
    line-height: 1;
    width: 100%;
    max-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 14px;
    height: 100%;
    font-size: 12px;
    margin-left: 10px;
  }

  .total-discount {
    color: #38a176;
    // border-bottom: 1px solid #8ca1f7;
    // padding-bottom: 1px;
    text-decoration: underline;
  }

  .discount-tooltip {
    position: absolute;
    display: none;
    color: black;
    background-color: #fff;
    box-shadow: 0 15px 38px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 15px;
    width: 250px;
    bottom: 100%;
    left: -100px;
    font-size: 12px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -10px;
      left: 35px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #ffffff transparent transparent transparent;
    }
  }
  .discount-tooltip-header {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .toltip {
    position: absolute;
    display: none;
    color: #231f20;
    background-color: #fff;
    box-shadow: 0 15px 38px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 15px;
    width: 200px;
    bottom: 100%;
    left: -39px;
    font-size: 12px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -10px;
      left: 35px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: #ffffff transparent transparent transparent;
    }
  }
}

.loading {
  position: relative;
  background-color: #e2e2e2;
  border-radius: 5px;
}

/* The moving element */
.loading::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(transparent),
    color-stop(rgba(255, 255, 255, 0.74)),
    to(transparent)
  );

  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);

  /* Adding animation */
  animation: loading 0.8s infinite;
}

/* Loading Animation */
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

.wa-contact-block {
  background-color: #fff;
  // display: flex;
  flex-direction: column;
  padding: 15px 5px;
  display: none;
  @media (max-width: 767px) {
    display: flex;
  }
}

.make-git-section {
  padding: 10px 40px;
  border-top: 1px solid #e9ecf3;
  background-color: #fcfdff;
  @media (max-width: 767px) {
    margin: 4px 0;
    padding: 10px 15px;
  }
  .summary-row {
    display: flex;
    justify-content: space-between;
  }

  .total-row {
    align-items: center;
    padding: 5px 0 0;
    color: #231f20;
    font-size: 16px;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    .total-name,
    .total-price {
      font-size: 16px;
    }
  }
}
.edit-icon {
  background: none;
}
.gift-toggle {
  background-color: #38a176 !important;
}

.form-gift {
  margin: 8px 0 0;
  display: flex;
  // align-items: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 767px) {
    justify-content: space-around;
  }
  .gift-label {
    display: block;
    margin: 0.5em 0 0;
  }
  .field-input {
    border: 1px solid #e9ecf3;
    border-radius: 4px;
    color: #3e4152;
    font-size: 14px;
    padding: 9px 15px;
    width: 100%;

    outline: none;
    line-height: 1.5;
    &::placeholder {
      font-size: 14px;
    }
  }
  .invoice_input {
    padding: 1em 0;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #38a176;
    border-color: #38a176;
  }
}

.payment-section {
  color: #5c5d5f;
  font-size: 12px !important;
  margin-top: 2px;
}

.discount-heading {
  display: flex;
  flex-direction: row;
}

.not-discount-applicable {
  color: #5c5d5f !important;
  font-style: italic;
  font-size: 11px !important;
}
