// nathabit-checkout-page  ---

// step-one
.nathabit-checkout-page {
  .reviewCartButton {
    display: none;
  }
  @media (max-width: 767px) {
    .reviewCartButton {
      display: inline-block;
    }
  }
  .address-wrap {
    margin: 0 0 0 24px;
    width: 100%;
    @media (max-width: 767px) {
      margin: 0 0 0 15px;
    }
    .subtitle {
      margin-bottom: 15px;
    }
    .payment-system {
      display: block;
    }
  }
  .address-title {
    color: #23354a;
    font-size: 14px;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    display: flex;
    letter-spacing: 0.3px;
  }
  .subtitle {
    color: #a2adbe;
    font-size: 12px;
    display: flex;
    margin-bottom: 10px;
    line-height: 1.7;
    letter-spacing: 0.26px;
  }
  .delivery-description {
    color: #0af;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
  }

  // popup
  .modal {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .modal-dialog {
    @media (max-width: 767px) {
      height: 100%;
      margin: 0;
      background-color: #fff;
      border-right: 1px solid #c7d1de;
      border-left: 1px solid #c7d1de;
    }
    @media (max-width: 991px) {
      // width: 100%;
    }
    @media (min-width: 576px) {
      // max-width: 1230px;
      // max-width: 100%;
      margin: 0;
    }
    @media (min-width: 991px) {
      // width: calc(100% - 50px);
    }
    @media (min-width: 1200px) {
      // width: calc(100% - 100px);
    }
    .modal-content {
      min-height: 100vh;
      border: 0;
      border-radius: 0;
      @media (max-width: 767px) {
        border: 0;
        border-radius: 0;
        background-color: #edeef2;
      }
    }
    .modal-body-wrap {
      @media (min-width: 768px) {
        min-height: calc(100vh - 94px);
      }
      @media (min-width: 768px) {
        display: flex;
        align-items: center;
      }
    }
    .modal-body {
      display: flex;
      padding: 15px 15px 110px;
      justify-content: space-around;
      @media (max-width: 767px) {
        padding: 0;
        flex-direction: column;
        background-color: #fff;
        margin-top: 5px;
      }
      @media (min-width: 768px) {
        justify-content: center;
        width: 100%;
      }
      // @media only screen and (max-width: 991px) and (min-width: 768px) {

      // }
      @media (min-width: 1200px) {
        // justify-content: space-between;
        padding: 15px 95px 110px;
      }
    }
    .modal-header {
      // justify-content: flex-start;
      justify-content: space-between;
      color: #25374c;
      border-bottom: 0;
      align-items: center;

      @media (min-width: 992px) {
        max-width: 1215px;
        margin: 0 auto;
        width: 100%;
      }

      @media (max-width: 767px) {
        background-color: #fff;
        border-top: 1px solid #c7d1de;
        border-bottom: 1px solid #c7d1de;
        border-radius: 0;
      }
      .modal-title {
        text-transform: uppercase;
        font-size: 16px;
        font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
          "Open Sans", "Helvetica Neue", sans-serif;
        display: flex;
        @media (max-width: 767px) {
          font-size: 12px;
          letter-spacing: 1.5px;
          color: #23354a;
        }
        > span {
          @media (min-width: 767px) {
            margin: 0 5px;
          }
        }
      }
      .close {
        margin: 0;
        padding: 0;
        margin-right: 32px;
        outline: none;
        @media (max-width: 767px) {
          margin-right: 0;
        }
      }
      &.back-btn {
        display: flex;
        @media (min-width: 992px) {
          padding: 35px 39px;
        }
      }
    }
    .map-section,
    .description-section {
      max-width: 50%;
      flex: 1 0 50%;
    }

    .description-section {
      max-width: 460px;
      position: relative;
      @media (min-width: 768px) {
        overflow: hidden;
      }
      @media (max-width: 767px) {
        padding: 15px 0;
        background-color: #fff;
        max-width: 100%;
        margin-left: 23px;
        margin-right: 23px;
      }
      .title-shipping-address {
        text-transform: uppercase;
        font-size: 12px;
        font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
          "Open Sans", "Helvetica Neue", sans-serif;
        color: #23354a;
        margin-bottom: 15px;
      }
      .checkbox-wrap {
        background-color: #fff;
      }
    }

    //	map-section
    .map-section {
      max-width: 100%;

      // @media only screen and (max-width: 991px) and (min-width: 768px) {
      // 	margin-bottom: 40px;
      // }
      @media (min-width: 768px) {
        max-width: 540px;
        margin-right: 15px;
      }
      @media (min-width: 1200px) {
        margin-right: 40px;
      }
      .map-wrap {
        @media (min-width: 768px) {
          border-bottom: 2px solid #c7d1de;
        }
        .map {
          height: 465px;
          @media (max-width: 767px) {
            height: calc(100vh - 296px);
            transition: height 0.25s ease-in;
            &.closed {
              height: 0;
            }
          }
          @media (min-width: 768px) {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
          }
        }
      }
      .location-wrap {
        padding: 15px 23px;
        @media (max-width: 767px) {
          border-top: 1px solid #c7d1de;
        }
        label {
          font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
            "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          color: #23354a;
          text-transform: uppercase;
          letter-spacing: 0.26px;
          margin: 0;
        }
        .location-item {
          font-family: Avenir-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
            "Open Sans", "Helvetica Neue", sans-serif;
          border: 0;
          width: 100%;
          color: #292d3d;
          font-size: 12px;
          letter-spacing: 0.26px;
          border-bottom: 2px solid #e9ecf3;
          padding: 5px 0;
          border-radius: 0;
        }
        .mobile-title {
          display: none;
          font-size: 14px;
          font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
            "Open Sans", "Helvetica Neue", sans-serif;
          @media (max-width: 767px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
          }
          &.is-active {
            .arrow-top {
              &:before {
                transform: rotate(-45deg);
              }
              &:after {
                transform: rotate(45deg);
              }
            }
          }
        }
      }
      .location-section {
        @media (min-width: 768px) {
          border: 2px solid #c7d1de;
          border-radius: 8px;
        }
      }
      .map-marker-label {
        position: absolute;
        color: #fff;
        font-size: 12px;
        background-color: #23354a;
        padding: 5px 10px;
        border-radius: 12px;
        margin-top: 25px;
      }
    }

    .nav-list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 37px;
      transition: all 0.5s ease-in-out;
      width: 100%;
      &.aside {
        margin-left: calc(-100% + 83px);
        flex-wrap: nowrap;
        // justify-content: flex-end;
      }
      @media (max-width: 767px) {
        padding: 15px 0 32px;
        margin-bottom: 0;
      }
      + .tab-content {
        @media (max-width: 767px) {
          transition: all 2s;
          &.opened {
            display: block;
          }
        }
      }
      .nav-item {
        position: relative;
        margin-right: 10px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .nav-link {
          padding: 0 0 0 30px;
          color: #2e3f53;
          letter-spacing: 0.26px;
          text-transform: uppercase;
          font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
            "Open Sans", "Helvetica Neue", sans-serif;
          font-size: 12px;
          transition: all 0.5s;
          &:before {
            content: "";
            position: absolute;
            border-radius: 50%;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            border: 2px solid #344254;
            width: 20px;
            height: 20px;
          }
          &.active {
            background-color: transparent;
            color: #38a176;
            &:before {
              border: 2px solid #38a176;
            }
            &:after {
              content: "";
              position: absolute;
              top: 0;
              bottom: 0;
              left: 5px;
              margin: auto;
              border-radius: 50%;
              background-color: #38a176;
              width: 10px;
              height: 10px;
            }
          }
          &.inactive {
            pointer-events: none;
            color: rgba(#2e3f53, 0.3);
            &:before {
              border: 2px solid rgba(#344254, 0.3);
            }
          }
          &.other {
            &.active {
            }
          }
        }
      }
    }
    .form-btn {
      // padding: 17.5px 0;
    }
    .other-body {
      opacity: 1;
      transition: all 0.5s;
      display: block;
      position: absolute;
      right: -100%;
      top: 30px;
      width: 100%;
      height: 100%;
      @media (max-width: 767px) {
        right: calc(-100% - 30px);
      }
      &.active {
        right: 0;
        transition: all 0.4s;
      }

      .new-address {
        &.aside {
          // position: absolute;
          // top: 30px;
          // right: 0;
          width: calc(100% - 100px);
          left: 100px;
        }
        .close {
          font-size: 18px;
          position: absolute;
          right: 0;
          top: 5px;
          @media (max-width: 767px) {
            top: 0;
          }
        }
      }
    }
    .shipping-address {
      .field-row {
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
        background-color: #fff;
        label {
          color: #1a2430;
          font-size: 11px;
          font-family: Avenir-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
            "Open Sans", "Helvetica Neue", sans-serif;
          text-transform: uppercase;
          letter-spacing: 0.26px;
          margin: 0;
          span {
            color: #2c0101;
            font-size: 11px;
            font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
              "Open Sans", "Helvetica Neue", sans-serif;
          }
        }
        .field-input {
          border-radius: 0;
        }
      }
      .form-btn {
        opacity: 1;
      }
    }
  }
}

// step-add-address ---
.step-add-address {
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    z-index: 9;
    width: 100%;
    -webkit-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
    -moz-box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.75);
    box-shadow: 0px -1px 10px rgba(50, 50, 50, 0.6);
  }
  .step-number {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .step-content {
    flex-wrap: wrap;
  }
  .step-delivery {
    display: none;
    .step-content {
      flex-wrap: nowrap;
      padding: 15px 0 7px;
      align-items: flex-start;
      @media (max-width: 767px) {
        padding: 0 16px 16px;
      }
    }
    .wrap-btn {
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
  .notify-wrap {
    @media (min-width: 768px) {
      margin-top: 15px;
      margin-bottom: 15px;
      height: 175px;
    }
    @media (min-width: 992px) {
      &:nth-child(odd) {
        margin-right: 15px;
      }
    }
    @media (min-width: 1200px) {
      &:nth-child(odd) {
        margin-right: 32px;
      }
    }
    .subtitle {
      line-height: 1.5;
    }
  }
  .step-body {
    > .subtitle {
      margin-bottom: 0;
    }
  }
  .add-address-section {
    .radio-wrap {
      @media (min-width: 768px) {
        display: none;
      }
    }
  }
  .wrap-btn {
    display: none;
    &.active {
      @media (max-width: 767px) {
        display: flex;
      }
    }
  }

  .close-btn {
    display: none;
    pointer-events: none;
  }

  @media (max-width: 767px) {
    .close-btn {
      display: block;
      pointer-events: auto;
      position: absolute;
      top: 6px;
      right: 10px;
    }
  }
}

.modal.fade:not(.show).left .modal-dialog {
  -webkit-transform: translate3d(-125%, 0, 0);
  transform: translate3d(-125%, 0, 0);
}
.modal.fade:not(.show).right .modal-dialog {
  -webkit-transform: translate3d(125%, 0, 0);
  transform: translate3d(125%, 0, 0);
}
.modal.fade:not(.show).bottom .modal-dialog {
  -webkit-transform: translate3d(0, 125%, 0);
  transform: translate3d(0, 125%, 0);
}

.ant-tooltip-inner {
  color: #fff;
  background-color: #ff3232;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: #ff3232;
}
.ant-popover-arrow {
  background-color: #ff3232;
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  // height: 150px;
  // padding: 48px;
  // overflow: hidden;
  // text-align: center;
  // background: #fafafa;
  // border: 1px solid #ebedf0;
  // border-radius: 2px;
}

.my-2 {
  .wrap-btn {
    display: flex;
    .form-btn {
      display: flex;
      flex-direction: column;
      padding: 10px 15px;
      &.total-btn {
        background-color: #38a176;
        font-size: 14px;
        @media (max-width: 768px) {
          border-radius: 0;
        }
        .price-description {
          font-size: 12px;
          // color: #38a176;
          font-family: Avenir-Medium, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
            "Open Sans", "Helvetica Neue", sans-serif;
        }
      }
      &.green-btn {
        justify-content: center;
        align-items: center;
      }
      &.proceed-btn {
        background-color: #1a242c;
        @media (max-width: 768px) {
          border-radius: 0;
        }
      }
    }
  }
}

#selectAddressButtonSection {
  .wrap-btn {
    .form-btn {
      padding: 11px;
    }
  }
}

.required-field::after {
  content: "*";
  color: #e32c2b;
}

.d-flex {
  display: flex;
}

.autocomplete-dropdown-container {
  z-index: 1;
}

.stateValid {
  & ~ label.required-field {
    top: -15px !important;
  }
  &.valid ~ label.error {
    display: none;
  }
}

.add-address-modal-backdrop {
  display: none;
  pointer-events: none;
}

@media (max-width: 767px) {
  .add-address-modal-backdrop {
    position: fixed;
    inset: 0;
    display: block;
    pointer-events: auto;
    &.is-disabled {
      display: none;
      pointer-events: none;
    }
  }
}

.proceed-to-login-pane {
  display: none;
  pointer-events: none;
}

@media (max-width: 767px) {
  .proceed-to-add-address-pane {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    pointer-events: auto;
    font-family: Avenir-Heavy, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: left;
    font-size: 12px;
    width: 100%;

    .price-btn {
      display: flex;
      flex-direction: column;
      padding: 10px 15px;
      background-color: #1a242c;
      width: 50%;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      pointer-events: none;

      .price-text {
        font-size: 14px;
        color: #98a5b7;
      }

      .price-desc {
        color: #38a176;
      }
    }

    .proceed-to-add-address-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      text-transform: uppercase;
      letter-spacing: 0.8px;
      color: #fff;
      background-color: #38a176;
      width: 50%;
    }
  }
}