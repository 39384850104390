.nathabit-checkout-page {
  .step-choose-payment {
    display: none;
  }

  // step-two
  .step-payment {
    @media (max-width: 767px) {
      background-color: #edeef2;
    }
    .payment-item {
      padding: 15px 0 7px;
      .step-content {
        align-items: flex-start;
      }
      &:not(:last-child) {
        .step-content {
          padding-bottom: 0;
        }
        .address-wrap {
          border-bottom: 1px solid #e9ecf3;
          padding-bottom: 15px;
        }
      }
    }
    .address-wrap {
      display: flex;
    }
    .wrap-btn {
      @media (max-width: 767px) {
        background-color: #fff;
        width: 100%;
        position: fixed;
        bottom: 0;
        padding: 8px 16px;
      }
    }
    .form-btn {
      width: 100%;
      max-width: 264px;
      padding: 11px 0;
      margin: 25px 0 17px 60px;
      pointer-events: none;
      align-items: center;
      @media (max-width: 767px) {
        margin: 0 auto;
        max-width: 100%;
      }
      &.is-active {
        pointer-events: auto;
      }
    }
    .step-body {
      @media (max-width: 767px) {
        padding: 0;
      }
      .step-title {
        @media (max-width: 767px) {
          background-color: #edeef2;
          padding: 24px 16px;
          text-transform: uppercase;
          font-size: 12px;
        }
      }
    }
  }
  .payment-list {
    padding: 0;
    margin: 0;
    list-style: none;
    @media (max-width: 767px) {
      padding: 0 16px;
      background-color: #fff;
      border-top: 1px solid #c7d1de;
      border-bottom: 1px solid #c7d1de;
    }
  }
  .pay-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cards-description {
    display: flex;

    .card-ico {
      margin-right: 10px;
      display: flex;
      align-items: center;
      img {
        height: 24px;
        @media (max-width: 767px) {
          height: 20px;
        }
      }
    }
  }
  .loadingCtn {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0.5px solid #b4cec4;
    min-height: 20px;
    border-radius: 5px;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px;
    background: #eafaf5;
    transition: height 600ms ease-out;
    min-width: 100%;
    .h {
      font-family: Avenir-Heavy;
      color: #23354a;
    }
    .subtitle {
      margin-bottom: 0 !important;
    }
    .dummyText {
      margin: 4px 0;
    }
    .tryAgain {
      width: fit-content;
      background-color: #978c8c1a;
      padding: 2px 4px;
      color: #38a176;
      // text-decoration: underline;
      border: none;
      &.is-active {
        border-color: transparent;
        border: none;
        outline: none;
      }
      &:focus {
        border-color: transparent;
        border: none;
        outline: none;
      }
    }
  }
  // custom radio button
  .radio-wrap {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin: 10px 20px 0 auto;
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      &:checked ~ .radio-checkmark {
        border: 2px solid #38a176;
        &:after {
          display: block;
          border-radius: 50%;
          background-color: #38a176;
        }
      }
    }
    .radio-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 2px solid #344254;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 10px;
        height: 10px;
        margin: auto;
      }
    }
  }

  // custom checkbox
  .checkbox-wrap {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    @media screen and (max-width: 750px) {
      justify-content: flex-start;
      width: 100%;
      padding: 0 0 10px;
    }
    label {
      font-size: 12px;
      margin-left: 10px;
      margin-bottom: 0;
      cursor: pointer;
    }

    .input-checkbox {
      border-radius: 4px;
      position: relative;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      border: 1px solid #d9d9d9;
      width: 18px;
      height: 18px;
      background: transparent;
      appearance: none;
      outline: none;
      &:checked {
        border: none;
        box-shadow: 0 0 0 10px #38a176 inset;
        &:before,
        &:after {
          opacity: 1;
        }
        &:focus {
          border-color: #434343;
        }
      }
      &:before,
      &:after {
        display: block;
        content: "";
        position: absolute;
        background-color: #fff;
        transform: scale(0.2);
        transition: all 0.2s ease-in-out 0.1s;
        opacity: 0;
        width: 2px;
        height: 6px;
      }
      &:before {
        right: 6px;
        left: auto;
        transform: rotate(45deg);
        top: 3.5px;
        width: 2px;
        height: 11px;
      }
      &:after {
        left: 5px;
        transform: rotate(-45deg);
        top: 7.5px;
      }
    }
  }

  .cod-payment-loader {
    width: 70%;
    height: 20px;
    background-color: gray;
    border-radius: 4px;
    animation: shimmer 2s infinite;

    @keyframes shimmer {
      0% {
        background-color: gray;
      }
      50% {
        background-color: lightgray;
      }
      100% {
        background-color: gray;
      }
    }
  }

  .error-message {
    width: 100%;
    padding: 11px 11px;
    margin: 25px 0 17px 32px;
    display: flex;
    transform: translateY(-10%);
    opacity: 0;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    background-color: #c94f4f;
    border-left: 4px solid #cd0000;
    border-radius: 4px;
    transition: all 400ms ease-in-out;
    pointer-events: none;

    @media (width <= 800px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 90%;
      margin-inline: auto;
    }

    &.is-active {
      display: flex;
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
    }

    .error-text-container {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .error-text {
      color: white;
      font-weight: bold;
      font-size: 14px;
    }

    .error-message-cross-icon {
      padding-inline: 3px;
      background-color: white;
      cursor: normal;
      border-radius: 100%;
      pointer-events: none;

      &.is-active {
        pointer-events: all;
        cursor: pointer;
      }
    }
  }
}

// wrap-step-payment
.wrap-step-payment {
  @media (max-width: 767px) {
    display: none;
  }
}

.payment-charge {
  display: flex;
  justify-content: space-between;
  // position: relative;
}

.payment-label {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1px 5px;
  border: 1px solid #b4cec4;
  background-color: #eafaf5;
  backdrop-filter: blur(4px);
  border-radius: 5px;
  font-size: 12px;
  color: #5d5d5d;
}

.payment-label-mobile {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1px 5px;
  border: 1px solid #b4cec4;
  background-color: #eafaf5;
  backdrop-filter: blur(4px);
  border-radius: 5px;
  font-size: 12px;
  color: #5d5d5d;
}

.cod-label {
  border: 1px solid #f3d7a07d;
  background-color: #fffcd475;
}

.payment-label {
  @media (max-width: 800px) {
    display: none;
  }
}

.payment-label-mobile {
  @media (min-width: 800px) {
    display: none;
  }
}
@keyframes blink {
  50% {
    color: transparent;
  }
}
.loader__dot {
  animation: 1500ms blink infinite;
}
.loader__dot:nth-child(2) {
  animation-delay: 375ms;
}
.loader__dot:nth-child(3) {
  animation-delay: 750ms;
}
.strike-amount {
  color: #5d5d5d;
  text-decoration: line-through;
}
.online-banner {
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.payment-options-desktop {
  @media (max-width: 800px) {
    display: none !important;
  }
}
.payment-mode-mobile {
  margin-bottom: 24px;
}

.payment-btn-design {
  display: flex;
  // margin-top: 24px;
  margin-bottom: 24px;
}
.payment-options-mobile {
  @media (min-width: 800px) {
    display: none !important;
  }
}
